import axios from '../api/server';
import {
  LOGIN_SUCCESS,
  USER_LOADED,
  PASSWORD_CHANGED,
  LOGOUT,
  AUTH_ERROR,
  ERROR
} from '../constants/ActionTypes';
import setAuthToken from '../../utils/setAuthToken';

// Load the current user
export const loadUser = () => async dispatch => {
  if (localStorage.token){
    setAuthToken(localStorage.token);
  }

  try {
    const response = await axios.get('/admin/auth/me');

    dispatch({
      type: USER_LOADED,
      payload: response.data
    });
  } catch(e){
    dispatch({
      type: AUTH_ERROR
    });
  }
}

// Login user
export const login = (credentials) => async dispatch => {
  console.log('body')
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify(credentials);

  try{
    const response = await axios.post('/admin/auth/login', body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data
    });
    dispatch(loadUser())
  }catch(e){
    const errors = e.response.data.errors;
    dispatch({
      type: AUTH_ERROR
    })
  }
}

// Change Password
export const changePassword = formValues => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const body = JSON.stringify(formValues);

  try{
    const response = await axios.post(`/admin/auth/change-password`, body, config);

    dispatch({
      type: PASSWORD_CHANGED
    });
  }catch(e){
    dispatch({
      type: ERROR
    });
  }
}

// Logout
export const logout = () => async dispatch => {
  try{
    await axios.post('/admin/auth/logout');
    dispatch({
      type: LOGOUT
    });
  }catch(e){
    dispatch({
      type: AUTH_ERROR
    })
  }
}
