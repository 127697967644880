import {
  FETCH_CATEGORIES,
  FETCH_CATEGORY,
  ADD_CATEGORY,
  DELETE_CATEGORY
} from '../constants/ActionTypes';

const initialState = {
  categories: [],
  category: null,
  loading: true
};

const categoriesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch(type){
    case FETCH_CATEGORIES:
          return {
            ...state,
            categories: payload,
            loading: false,
            category: null
          }
          case FETCH_CATEGORY:
            return {
              ...state,
              category: payload,
              loading: false
            }
            case ADD_CATEGORY:
              return{
                ...state,
                categories: [payload, ...state.categories],
                loading: false
              }
            case DELETE_CATEGORY:
              return {
                ...state,
                categories: state.categories.filter(category => category._id !== payload._id),
                loading: false
              }
          default:
            return state;
  }
}

export default categoriesReducer;
