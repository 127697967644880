import {
  FETCH_OVERVIEW,
} from '../constants/ActionTypes';

const initialState = {
  overview: [],
  loading: true
};

const statsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch(type){
    case FETCH_OVERVIEW:
      return{
        ...state,
        overview: payload,
        loading: false
      }
    default:
      return state
  }
};

export default statsReducer;
