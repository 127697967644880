import {
  FETCH_ORDERS,
  FETCH_ORDER,
  FETCH_RECENT_ORDERS,
  CLEAR_ORDERS
} from '../constants/ActionTypes';

const initialState = {
  orders: [],
  order: null,
  loading: true
};

const ordersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch(type){
    case FETCH_ORDERS:
      return{
        ...state,
        orders: payload,
        loading: false,
        order: null
      }
    case FETCH_RECENT_ORDERS:
      return{
        ...state,
        orders: payload,
        loading: false,
        order: null
      }
    case FETCH_ORDER:
      return{
        ...state,
        order: payload,
        loading: false
      }
    case CLEAR_ORDERS:
      return initialState;
    default:
      return state
  }
};

export default ordersReducer;
