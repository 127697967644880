import {
	LOGIN_SUCCESS,
	USER_LOADED,
	LOGOUT,
	AUTH_ERROR
} from '../constants/ActionTypes';

const initialState = {
	token: localStorage.getItem('token'),
	isAuthenticated: null,
	loading: true,
	user: null
}

export default function(state = initialState, action) {
	const { type, payload } = action;

	switch(type){
		case USER_LOADED:
			return{
				...state,
				isAuthenticated: true,
				loading: false,
				user: payload
			};
		case LOGIN_SUCCESS:
			return{
				...state,
				...payload,
				isAuthenticated: true,
				loading: false
			};
		case LOGOUT:
			return {
				...state,
				token: null,
				isAuthenticated: false,
				loading: false,
				user: null
			};
		case AUTH_ERROR:
			return {
				...state,
				isAuthenticated: false,
				loading: false,
				user: null
			};
		default:
			return state;
	}
}
