import {
  FETCH_CONTACTS,
  ADD_CONTACT,
  DELETE_CONTACT
} from '../constants/ActionTypes';

const initialState = {
  contacts: [],
  contact: null,
  loading: true
};

const contactsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CONTACTS:
      return{
        ...state,
        contacts: payload,
        contact: null,
        loading: false
      }
    case ADD_CONTACT:
      return{
        ...state,
        contacts: [payload, ...state.contacts],
        loading: false
      }
    case DELETE_CONTACT:
        return {
        ...state,
        contacts: state.contacts.filter(contact => contact._id !== payload._id),
        loading: false
      }
    default: return state;

  }
}

export default contactsReducer;
