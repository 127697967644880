// Locked out some for production

import {
  DashboardOutlined,
  ShoppingCartOutlined,
  FundOutlined,
  CheckOutlined,
  UnorderedListOutlined,
  ProfileOutlined,
  ContactsOutlined,
  QuestionCircleOutlined,
  PoundOutlined,
  ToolOutlined
} from '@ant-design/icons';

const dashBoardNavTree = [{
  key: 'home',
  path: '/app/home',
  title: 'home',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: []
},{
  key: 'orders',
  path: '/app/orders',
  title: 'Orders',
  icon: PoundOutlined,
  breadcrumb: true,
  submenu: []
},{
  key: 'eCommerce',
  path: '',
  title: 'ecommerce',
  icon: ShoppingCartOutlined,
  breadcrumb: true,
  submenu: [
    {
      key: 'products',
      path: '',
      title: 'Products',
      icon: ShoppingCartOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'products-list',
          path: '/app/products',
          title: 'View Products',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'add-product',
          path: '/app/products/add',
          title: 'Add Product',
          icon: '',
          breadcrumb: true,
          submenu: []
        }
      ]
    },{
      key: 'categories',
      path: '',
      title: 'Categories',
      icon: ShoppingCartOutlined,
      breadcrumb: true,
      submenu: [{

            key: 'categories-list',
            path: '/app/categories',
            title: 'View Categories',
            icon: '',
            breadcrumb: true,
            submenu: []
          },{
            key: 'add-category',
            path: '/app/categories/add',
            title: 'Add Category',
            icon: '',
            breadcrumb: true,
            submenu: []
      }]
    },
    {
      key: 'brands',
      path: '',
      title: 'Brands',
      icon: ShoppingCartOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'brands-list',
          path: '/app/brands',
          title: 'View Brands',
          icon: '',
          breadcrumb: true,
          submenu: []
        },{
          key: 'add-brand',
          path: '/app/brands/add',
          title: 'Add Brand',
          icon: '',
          breadcrumb: true,
          submenu: []
        }
      ]
    }
  ]
},
{
  key: 'Apps',
  path: '',
  title: 'Apps',
  icon: '',
  breadcrumb: true,
  submenu:[
{
  key: 'todo',
  path: '/app/apps/todo',
  title: 'ToDo',
  icon: CheckOutlined,
  breadcrumb: true,
  submenu: []
},
{
  key: 'contacts',
  path: '/app/apps/contacts',
  title: 'Contacts',
  icon: ContactsOutlined,
  breadcrumb: true,
  submenu:[]
}]
},
{
  key: 'site-management',
  path: '',
  title: 'Site Management',
  icon: '',
  breadcrumb: true,
  submenu: [
    {
      key: 'site1',
      path: '/app/site/settings',
      title: 'Settings',
      icon: ToolOutlined,
      breadcrumb: true,
      submenu: []
    }
  ]
},
{
  key: 'misc',
  path: '',
  title: 'misc',
  icon: '',
  breadcrumb: true,
  submenu: [
    {
      key: 'support',
      path: '/app/support',
      title: 'Support',
      icon: QuestionCircleOutlined,
      breadcrumb: true,
      submenu: []
    }
  ]
}]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
