import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import PrivateRoute from '../PrivateRoute'
import '../../assets/css/custom.css'

export const AppViews = ({match}) => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
    <div class="content-wrapper-before gradient-45deg-indigo-purple"></div>
      <Switch>
        <Route exact path={`${match.url}/404-not-found`} component={lazy(() => import('../auth-views/errors/error-page-1'))} />
        <PrivateRoute path={`${match.url}/home`} component={lazy(() => import(`./home`))} />
        <PrivateRoute path={`${match.url}/orders`} component={lazy(() => import(`./orders`))} />
        <PrivateRoute path={`${match.url}/order/:id`} component={lazy(() => import('./invoice'))} />
        <PrivateRoute exact path={`${match.url}/products`} component={lazy(() => import(`./ecommerce/products/product-list`))} />
        <PrivateRoute exact path={`${match.url}/products/add`} component={lazy(() => import(`./ecommerce/products/add-product`))} />
        <PrivateRoute exact path={`${match.url}/product/edit/:id`} component={lazy(() => import(`./ecommerce/products/edit-product`))} />
        <PrivateRoute exact path={`${match.url}/categories`} component={lazy(() => import(`./ecommerce/categories/categories`))} />
        <PrivateRoute exact path={`${match.url}/categories/add`} component={lazy(() => import(`./ecommerce/categories/add-category`))} />
        <PrivateRoute exact path={`${match.url}/category/edit/:id`} component={lazy(() => import(`./ecommerce/categories/edit-category`))} />
        <PrivateRoute exact path={`${match.url}/brands`} component={lazy(() => import(`./ecommerce/brands/brands`))} />
        <PrivateRoute exact path={`${match.url}/brands/add`} component={lazy(() => import(`./ecommerce/brands/add-brand`))} />
        <PrivateRoute exact path={`${match.url}/brand/edit/:id`} component={lazy(() => import(`./ecommerce/brands/edit-brand`))} />
        <PrivateRoute exact path={`${match.url}/invoicing`} component={lazy(() => import(`./apps/invoicing/InvoiceDash`))} />
        <PrivateRoute exact path={`${match.url}/invoicing/list`} component={lazy(() => import(`./apps/invoicing/InvoiceList`))} />
        <PrivateRoute exact path={`${match.url}/invoicing/add`} component={lazy(() => import(`./apps/invoicing/AddInvoice`))} />
        <PrivateRoute exact path={`${match.url}/apps/pinboard`} component={lazy(() => import(`./apps/scrumboard`))} />
        <PrivateRoute exact path={`${match.url}/apps/todo`} component={lazy(() => import(`./apps/todo`))} />
        <PrivateRoute exact path={`${match.url}/apps/contacts`} component={lazy(() => import(`./apps/contacts`))} />
        <PrivateRoute exact path={`${match.url}/site/settings`} component={lazy(() => import(`./site/settings`)) } />
        <PrivateRoute exact path={`${match.url}/site/profile`} component={lazy(() => import(`./site/settings`)) } />
        <PrivateRoute exact path={`${match.url}/site/billing`} component={lazy(() => import(`./site/settings`)) } />
        <PrivateRoute exact path={`${match.url}/site/notify`} component={lazy(() => import(`./site/settings`)) } />
        <PrivateRoute exact path={`${match.url}/site/site-settings`} component={lazy(() => import(`./site/settings`))} />
        <Redirect from={`${match.url}`} to={`${match.url}/404-not-found`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;
