import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import ordersReducer from './Orders';
import productsReducer from './Products';
import categoriesReducer from './Categories';
import brandsReducer from './Brands';
import statsReducer from './Stats';
import todosReducer from './Todos';
import contactsReducer from './Contacts';
import invoicesReducer from './Invoices';
import { LOGOUT, CLEAR_STATE } from '../constants/ActionTypes';

const appReducer = combineReducers({
    theme: Theme,
    auth: Auth,
    orders: ordersReducer,
    products: productsReducer,
    categories: categoriesReducer,
    brands: brandsReducer,
    stats: statsReducer,
    todos: todosReducer,
    contacts: contactsReducer,
    invoices: invoicesReducer
});

const rootReducer = (state, action) => {

  switch(action.type){
    case LOGOUT:
      state = undefined;
      break;
    case CLEAR_STATE:
      state.products = undefined;
      state.orders = undefined;
      state.brands = undefined;
      state.categories = undefined;
      break;
    default:
      return appReducer(state, action)
  }
}

export default rootReducer;
