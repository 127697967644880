import {
  FETCH_TODOS,
  ADD_TODO,
  DELETE_TODO,
  UPDATE_TODO,
  FILTER_COMPLETED_TODOS,
  FILTER_PRIORITY_TODOS
} from '../constants/ActionTypes';

const initialState = {
  todos: [],
  todo: null,
  loading: true
};

const todosReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TODOS:
      return{
        ...state,
        todos: payload,
        todo: null,
        loading: false
      }
    case ADD_TODO:
      return{
        ...state,
        todos: [payload, ...state.todos],
        loading: false
      }
    case DELETE_TODO:
      return {
      ...state,
      todos: state.todos.filter(todo => todo._id !== payload._id),
      loading: false
    }
    case UPDATE_TODO:
      return{
        ...state,
        todos: state.todos.map(todo => {
          if(todo._id === payload._id){
            todo.completed = payload.completed
            return todo
          }else{
            return todo
          }
        }),
        loading: false
      }
    case FILTER_COMPLETED_TODOS:
      return{
        ...state,
        todos: state.todos.filter(todo => todo.completed === payload),
        loading: false
      }
    case FILTER_PRIORITY_TODOS:
      return{
        ...state,
        todos: state.todos.filter(todo => todo.priority === payload),
        loading: false
      }
    default: return state;

  }
}

export default todosReducer;
