import React from 'react'
import { APP_NAME } from 'configs/AppConfig';

export default function Footer() {
	return (
		<footer className="footer gradient-45deg-indigo-purple">
			<span>Copyright  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold"><a href="https://www.billymccord.io">Billy McCord :)</a></span> All rights reserved.</span>
			<div>
				<a href="/#" onClick={e => e.preventDefault()}>Term & Conditions</a>
				<span className="mx-2 text-muted"> | </span>
				<a href="/#" onClick={e => e.preventDefault()}>Privacy & Policy</a>
			</div>
		</footer>
	)
}
