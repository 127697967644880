import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import setAuthToken from 'utils/setAuthToken';
import { loadUser } from 'redux/actions/Auth';
import { ToastContainer } from 'react-toastify';

const App = () => {

  useEffect(() => {
  if(localStorage.token) {
    setAuthToken(localStorage.token);
    store.dispatch(loadUser());
  }
}, []);

  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/" component={Views}/>
          </Switch>
        </Router>
      </Provider>
      <ToastContainer />
    </div>
  );
}

export default App;
