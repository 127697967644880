import {
  FETCH_BRANDS,
  FETCH_BRAND,
  ADD_BRAND,
  DELETE_BRAND
} from '../constants/ActionTypes';

const initialState = {
  brands: [],
  brand: null,
  loading: true
};

const brandsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_BRANDS:
          return {
            ...state,
            brands: payload,
            loading: false,
            brand: null
          }
          case FETCH_BRAND:
            return {
              ...state,
              brand: payload,
              loading: false,
              brands: []
            }
            case ADD_BRAND:
              return{
                ...state,
                brands: [payload, ...state.brands],
                loading: false
            }
            case DELETE_BRAND:
            return {
            ...state,
            brands: state.brands.filter(brand => brand._id !== payload._id),
            loading: false
          }
    default:
      return state;
  }
}

export default brandsReducer;
