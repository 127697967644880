import {
  FETCH_PRODUCTS,
  FETCH_PRODUCT,
  ADD_PRODUCT,
  FETCH_SPECIAL_PRODUCTS,
  UPDATE_SPECIAL_PRODUCT,
  DELETE_PRODUCT
} from '../constants/ActionTypes';

const initialState = {
  products: [],
  specialProducts: [],
  product: null,
  loading: true
};

const productsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_PRODUCTS:
      return{
        ...state,
        products: payload,
        product: null,
        loading: false
      }
    case FETCH_PRODUCT:
      return{
        ...state,
        product: payload,
        loading: false
      }
    case ADD_PRODUCT:
      return{
        ...state,
        products: [payload, ...state.products],
        loading: false
      }
    case FETCH_SPECIAL_PRODUCTS:
      return{
        ...state,
        specialProducts: payload,
        loading: false
      }
    case DELETE_PRODUCT:
            return {
              ...state,
              products: state.products.filter(product => product._id !== payload._id),
              loading: false
        }
    default: return state;

  }
}

export default productsReducer;
