// Orders
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDER = 'FETCH_ORDER';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const FETCH_RECENT_ORDERS = 'FETCH_RECENT_ORDERS';
// Products
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_SPECIAL_PRODUCTS = 'FETCH_SPECIAL_PRODUCTS';
export const UPDATE_SPECIAL_PRODUCT = 'UPDATE_SPECIAL_PRODUCT';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DOWNLOAD_STOCK = 'DOWNLOAD_STOCK';
// Categories
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
// Brands
export const FETCH_BRANDS = 'FETCH_BRANDS';
export const FETCH_BRAND = 'FETCH_BRAND';
export const ADD_BRAND = 'ADD_BRAND';
export const EDIT_BRAND = 'EDIT_BRAND';
export const DELETE_BRAND = 'DELETE_BRAND';
// Auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_LOADED = 'USER_LOADED';
export const LOGOUT = 'LOGOUT';
export const AUTH_ERROR = 'AUTH_ERROR';
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';
// Stats
export const FETCH_OVERVIEW = 'FETCH_OVERVIEW';
// Apps: Todos
export const FETCH_TODOS = 'FETCH_TODOS';
export const FETCH_TODO = 'FETCH_TODO';
export const ADD_TODO = 'ADD_TODO';
export const UPDATE_TODO = 'UPDATE_TODO';
export const DELETE_TODO = 'DELETE_TODO';
export const FILTER_COMPLETED_TODOS = 'FETCH_COMPLETED_TODOS';
export const FILTER_PRIORITY_TODOS = 'FILTER_PRIORITY_TODOS';
// Apps: Contacts
export const FETCH_CONTACTS = 'FETCH_CONTACTS';
export const FETCH_CONTACT = 'FETCH_CONTACT';
export const ADD_CONTACT = 'ADD_CONTACT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';
// Apps: Invoicing
export const FETCH_INVOICES = 'FETCH_INVOICES';
export const FETCH_INVOICE = 'FETCH_INVOICE';
export const ADD_INVOICE = 'ADD_INVOICE';
export const EDIT_INVOICE = 'EDIT_INVOICE';
export const DELETE_INVOICE = 'DELETE_INVOICE';


// General error
export const ERROR = 'ERROR';
// Clear state
export const CLEAR_STATE = 'CLEAR_STATE';
