import {
  FETCH_INVOICES,
  ADD_INVOICE,
  DELETE_INVOICE
} from '../constants/ActionTypes';

const initialState = {
  invoices: [],
  invoice: null,
  loading: true
};

const invoicesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_INVOICES:
      return{
        ...state,
        invoices: payload,
        invoice: null,
        loading: false
      }
    case ADD_INVOICE:
      return{
        ...state,
        invoices: [payload, ...state.invoices],
        loading: false
      }
    case DELETE_INVOICE:
      return {
      ...state,
      invoices: state.invoices.filter(invoice => invoice._id !== payload._id),
      loading: false
    }
    default: return state;

  }
}

export default invoicesReducer;
